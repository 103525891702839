export const Status = {
    IN_STOCK: 'in_stock',
    IN_TRANSIT: 'in_transit',
    IN_RETAIL: 'in_retail'
};

export const Roles = {
    SUPERVISOR: 'supervisor',
    ADMIN: 'admin',
    OPERATOR: 'operator',
    SUPERADMIN: 'superadmin'
};

export const RoleColor = {
    supervisor: 'orange',
    admin: 'green',
    operator: 'yellow'
};

export const StatusDisplay = {
    in_stock: {
        displayName: 'In Stock',
        color: '#FFB300'
    },
    in_transit: {
        displayName: 'In Transit',
        color: '#2FCCFF'
    },
    in_retail: {
        displayName: 'In Retail',
        color: '#00AB55'
    }
};

export const Warehouse = {
    STOCK: 'stock',
    RETAIL: 'retail'
};

export const StatusColor = {
    in_stock: '#FFB300',
    in_transit: '#2FCCFF',
    in_retail: '#00AB55'
};

export const Table = {
    PURCHASE_ORDERS: 'purchase_orders',
    ARTICLE_TYPES: 'article_types',
    SECTIONS: 'sections',
    ORDERS: 'orders',
    SHIPMENTS: 'shipments',
    VENDORS: 'vendors',
    PARTIES: 'parties',
    PAIRS: 'pairs',
    BOXES: 'boxes',
    PROFILES: 'profiles',
    LOOSE: 'loose'
};

export const DEFAULT_LIST_PAGE_SIZE = 10;
