class AppResult {
    error = null;
    data = null;

    constructor(data, error) {
        this.data = data;
        this.error = error;
    }

    hasError() {
        return this.error != null;
    }

    static withError(error) {
        return new AppResult(null, error);
    }

    static withData(data) {
        return new AppResult(data, null);
    }
}

export const withSafety = async (block) => {
    try {
        return await block();
    } catch (error) {
        console.error(error);
        return AppResult.withError(error.message);
    }
};

export default AppResult;
