import { authService } from '.';
import { supabase, supabaseAuth } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class AuthService {
    async signIn({ email, password }) {
        return withSafety(async () => {
            const { error } = await supabaseAuth.signIn({ email, password });
            if (error) throw error;

            return AppResult.withData(this.getCurrentUser());
        });
    }

    async signOut() {
        return withSafety(async () => {
            const { error } = await supabaseAuth.signOut();
            if (error) throw error;
        });
    }

    getCurrentUser() {
        return supabaseAuth.user();
    }

    async fetchProfile() {
        return withSafety(async () => {
            const { id } = this.getCurrentUser();

            const { data, error } = await supabase
                .from(Table.PROFILES)
                .select(columns('*', `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('is_active', true)
                .eq('id', id)
                .single();

            console.log('Error: ', error);
            console.log('Data: ', data);

            if (error || data == null) {
                authService.signOut();
                throw new Error('User not found');
            }

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default AuthService;
