import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase, toSnakeCase, columns } from '../utils/utils';

class PairService {
    async updatePair({ id, ...changes }) {
        return withSafety(async () => {
            const Pair = {
                ...toSnakeCase(changes),
                updated_by: authService.getCurrentUser().id,
                updated_at: new Date()
            };

            const { data, error } = await supabase.from(Table.PAIRS).update(Pair).eq('id', id);

            if (error) throw new Error(error.message);

            return AppResult.withData(data);
        });
    }

    async deletePair(id) {
        return await this.updatePair({ id, isArchived: true });
    }

    async getPairById(id) {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.PAIRS)
                .select(
                    columns(
                        `*`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`*`)})`,
                        `updated_by(${columns(`*`)})`
                    )
                )
                .eq('id', id)
                .single();

            if (error) throw new Error(error.message);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getPairsWithBoxId(boxId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PAIRS)
                .select(
                    columns(
                        `*`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`*`)})`,
                        `updated_by(${columns(`*`)})`
                    )
                )
                .eq('box_id', boxId)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getArticleImageUrl(id) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PAIRS)
                .select(columns(`article_type:article_type_id(${columns(`id`, `image_ext`)})`))
                .eq('id', id)
                .single()
                .throwOnError();

            console.log(data);
            const articleData = toCamelCase(data).articleType;

            const { data: imageData, error } = await supabase.storage
                .from('article-types')
                .createSignedUrl(`private/${articleData.id}.${articleData.imageExt}`, 120);

            console.log(data, error);
            if (error) throw error;

            return AppResult.withData({ url: imageData.signedURL });
        });
    }

    async createPair(details) {
        return withSafety(async () => {
            const payload = toSnakeCase({ ...details, createdBy: authService.getCurrentUser().id });

            const { data } = await supabase.from(Table.PAIRS).insert(payload).throwOnError();
            const pair = await this.getPairById(data[0].id);

            return pair;
        });
    }

    async getPairsRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from('retail_aggregation')
                .select(
                    columns(
                        `*`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`
                    ),
                    {
                        count: 'exact'
                    }
                )
                .range(from, to)
                .throwOnError();

            console.log('Data: ', data);
            return AppResult.withData({ pairs: toCamelCase(data), count });
        });
    }

    async getRetailAggregation() {
        return withSafety(async () => {
            const { data } = await supabase
                .from('retail_aggregation')
                .select(
                    columns(
                        `*`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`
                    )
                )
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async removePairsWithArticleType(articleTypeId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PAIRS)
                .delete()
                .eq('article_type_id', articleTypeId)
                .is('order_id', null)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default PairService;
