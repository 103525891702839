import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase, toSnakeCase } from '../utils/utils';

class LooseService {
    getBatchesRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from(Table.LOOSE)
                .select(
                    columns(
                        `*`,
                        `party:party_id(${columns(`*`)})`,
                        `vendor:vendor_id(${columns(`*`)})`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`*`)})`
                    ),
                    { count: 'exact' }
                )
                .range(from, to)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData({ batches: toCamelCase(data), count });
        });
    }

    getAllBatches() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.LOOSE)
                .select(
                    columns(
                        `*`,
                        `party:party_id(${columns(`*`)})`,
                        `vendor:vendor_id(${columns(`*`)})`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`
                    )
                )
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    getAllBatchesWithConfig({ type, fromDate, toDate }) {
        console.log(fromDate, toDate);
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.LOOSE)
                .select(
                    columns(
                        `*`,
                        `party:party_id(${columns(`*`)})`,
                        `vendor:vendor_id(${columns(`*`)})`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`
                    )
                )
                .eq('batch_type', type)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    createBatch(details) {
        return withSafety(async () => {
            await supabase
                .from(Table.LOOSE)
                .insert(toSnakeCase({ ...details, createdBy: authService.getCurrentUser().id }))
                .throwOnError();

            return AppResult.withData();
        });
    }

    activateBatch(id, type) {
        return withSafety(async () => {
            await supabase
                .rpc(type === 'box' ? 'create_batch_boxes' : 'create_batch_pairs', {
                    batch_id: id
                })
                .throwOnError();

            return AppResult.withData();
        });
    }

    deleteBatch(id) {
        return withSafety(async () => {
            await supabase
                .rpc('delete_batch', {
                    m_batch_id: id
                })
                .throwOnError();

            return AppResult.withData();
        });
    }

    getItemsWithLooseId({ id, itemType }) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(itemType === 'box' ? Table.BOXES : Table.PAIRS)
                .select('id')
                .eq('loose_id', id)
                .throwOnError();

            console.log(data);

            return AppResult.withData(data);
        });
    }
}

export default LooseService;
