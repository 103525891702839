import { supabase } from '../adapters/supabase';
import { Status, Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class AnalyticService {
    async getBoxCountWhereStatus(status) {
        return withSafety(async () => {
            const { count } = await supabase
                .from(Table.BOXES)
                .select('id', { count: 'exact' })
                .eq('is_archived', false)
                .eq('status', status)
                .is('order_id', null)
                .throwOnError();

            return AppResult.withData(count);
        });
    }

    async getPairCount() {
        return withSafety(async () => {
            const { count } = await supabase
                .from(Table.PAIRS)
                .select('id', { count: 'exact' })
                .eq('is_archived', false)
                .is('order_id', null)
                .throwOnError();

            return AppResult.withData(count);
        });
    }

    async getByKey({ model, key, value }) {
        if (model === Table.ARTICLE_TYPES) return this.getArticleTypes({ key, value });

        return withSafety(async () => {
            const operator = key === 'id' || key === 'type' ? 'eq' : 'ilike';
            value = key === 'id' || key === 'type' ? value : `%${value}%`;

            const { data } = await supabase
                .from(model)
                .select(ModelColumns[model])
                [operator](key, value)
                .eq('is_archived', false)
                .throwOnError();

            if (data.length === 0) throw new Error('No item found');

            let res = toCamelCase(data);

            if (model === 'shipments') {
                res = this.getFilteredShipments(res);
            } else if (model === 'orders') {
                res = this.getFilteredOrders(res);
            }

            return AppResult.withData(res);
        });
    }

    getFilteredShipments(data) {
        data = data.filter(
            (item) => item.articleType != null && item.party != null && item.section != null
        );
        if (data.length === 0) throw new Error('No item found');

        return data;
    }

    getFilteredOrders(data) {
        data = data.filter((item) => item.vendor != null);
        if (data.length === 0) throw new Error('No item found');

        return data;
    }

    async getArticleTypes({ key, value }) {
        return withSafety(async () => {
            const operator = key === 'id' ? 'eq' : 'ilike';
            value = key === 'id' ? value : `%${value}%`;

            const { data } = await supabase
                .from('article_types')
                .select(ModelColumns['article_types'])
                [operator](key, value)
                .eq('is_archived', false)
                .throwOnError();

            if (data.length === 0) throw new Error('No item found');

            const articleTypes = await Promise.all(
                data.map(async (item) => {
                    const { count: stockCount } = await supabase
                        .from('boxes')
                        .select(`id`, { count: 'exact' })
                        .eq('is_archived', false)
                        .eq('status', Status.IN_STOCK)
                        .eq('article_type_id', item.id)
                        .is('order_id', null);

                    const { count: retailCount } = await supabase
                        .from('pairs')
                        .select(`id`, { count: 'exact' })
                        .eq('is_archived', false)
                        .eq('article_type_id', item.id)
                        .is('order_id', null);

                    return { ...item, stockCount, retailCount };
                })
            );

            return AppResult.withData(toCamelCase(articleTypes));
        });
    }
}

const ModelColumns = {
    vendors: columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
    parties: columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
    sections: columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
    article_types: columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
    orders: columns(
        `*`,
        `vendor:vendor_id(${columns(`*`)})`,
        `created_by(${columns(`*`)})`,
        `updated_by(${columns(`*`)})`
    ),
    pairs: columns(
        `*`,
        `section:section_id(${columns(`name`, `warehouse`)})`,
        `article_type:article_type_id(${columns(`name`, `size`, `color`, `size_unit`)})`,
        `created_by(${columns(`*`)})`,
        `updated_by(${columns(`*`)})`
    ),
    boxes: columns(
        `*`,
        `section:section_id(${columns(`name`, `warehouse`)})`,
        `article_type:article_type_id(${columns(`name`, `size`, `color`, `size_unit`)})`,
        `created_by(${columns(`*`)})`,
        `updated_by(${columns(`*`)})`
    ),
    shipments: columns(
        `*`,
        `party:party_id(${columns(`*`)})`,
        `section:section_id(${columns(`*`)})`,
        `article_type:article_type_id(${columns(`*`)})`,
        `created_by(${columns(`*`)})`,
        `updated_by(${columns(`*`)})`
    )
};

export default AnalyticService;
