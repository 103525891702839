import AuthService from './AuthService';
import VendorService from './VendorService';
import SectionService from './SectionService';
import ArticleTypeService from './ArticleTypeService';
import PartyService from './PartyService';
import ShipmentService from './ShipmentService';
import BoxService from './BoxService';
import PairService from './PairService';
import OrderService from './OrderService';
import AnalyticService from './AnalyticService';
import StaffService from './StaffService';
import LooseService from './LooseService';
import PurchaseOrderService from './PurchaseOrderService';

export const authService = new AuthService();
export const vendorService = new VendorService();
export const sectionService = new SectionService();
export const articleTypeService = new ArticleTypeService();
export const partyService = new PartyService();
export const shipmentService = new ShipmentService();
export const boxService = new BoxService();
export const pairService = new PairService();
export const orderService = new OrderService();
export const analyticService = new AnalyticService();
export const staffService = new StaffService();
export const looseService = new LooseService();
export const purchaseOrderService = new PurchaseOrderService();
