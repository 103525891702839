import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase, toSnakeCase, columns } from '../utils/utils';

class SectionService {
    async getAllSections() {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.SECTIONS)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('is_archived', false)
                .order('created_at', { ascending: false });

            if (error) throw error;

            return AppResult.withData(toCamelCase(data));
        });
    }

    async createSection(payload) {
        return withSafety(async () => {
            const newObj = {
                ...toSnakeCase(payload),
                is_archived: false,
                created_by: authService.getCurrentUser().id
            };

            const { data, error } = await supabase.from(Table.SECTIONS).insert([newObj]);

            if (error) throw new Error(error.message);

            return AppResult.withData(data);
        });
    }

    async updateSection({ id, warehouse, ...changes }) {
        return withSafety(async () => {
            const updatedObj = {
                ...toSnakeCase(changes),
                updated_by: authService.getCurrentUser().id,
                updated_at: `now()`
            };

            const { data } = await supabase
                .from(Table.SECTIONS)
                .update(updatedObj)
                .eq('id', id)
                .throwOnError();

            return AppResult.withData(data);
        });
    }

    async deleteSection(id) {
        return await this.updateSection({ id, isArchived: true });
    }

    async getSectionById(id) {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.SECTIONS)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('id', id)
                .single();

            if (error) throw new Error(error.message);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getSectionsRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from(Table.SECTIONS)
                .select(
                    columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
                    { count: 'exact' }
                )
                .eq('is_archived', false)
                .range(from, to)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData({ sections: toCamelCase(data), count });
        });
    }

    async getSectionWhereNameLike(name, warehouse) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.SECTIONS)
                .select(columns(`*`))
                .eq('is_archived', false)
                .in('warehouse', warehouse)
                .ilike('name', `%${name}%`)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default SectionService;
