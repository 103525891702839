import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class OrderService {
    async deleteOrder(orderId) {
        return withSafety(async () => {
            const { data } = await supabase
                .rpc('delete_order', {
                    m_order_id: orderId
                })
                .throwOnError();

            return AppResult.withData(data);
        });
    }

    async createNewOrder(payload) {
        return withSafety(async () => {
            const { data } = await supabase
                .rpc('create_order', {
                    box_ids: payload.boxes,
                    pair_ids: payload.pairs,
                    vendor_id: payload.vendor,
                    note: payload.note,
                    image_ext: payload.imageExt
                })
                .throwOnError();

            if (payload.file != null) {
                await supabase.storage
                    .from('orders')
                    .upload(`invoices/${data[0]}.${payload.imageExt}`, payload.file, {
                        cacheControl: '3600',
                        upsert: true
                    });
            }

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getOrdersRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from(Table.ORDERS)
                .select(
                    columns(
                        `*`,
                        `vendor:vendor_id(${columns(`*`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`*`)})`,
                        `updated_by(${columns(`*`)})`
                    ),
                    { count: 'exact' }
                )
                .eq('is_archived', false)
                .range(from, to)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData({ orders: toCamelCase(data), count });
        });
    }

    async getAllOrders() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.ORDERS)
                .select(
                    columns(
                        `*`,
                        `vendor:vendor_id(${columns(`*`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`*`)})`,
                        `updated_by(${columns(`*`)})`
                    )
                )
                .eq('is_archived', false)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    getImageUrl(id, ext) {
        const { data } = supabase.storage.from('orders').getPublicUrl(`invoices/${id}.${ext}`);
        return data.publicURL;
    }

    async getBoxes(orderId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.BOXES)
                .select(
                    columns(
                        `*`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`full_name`)})`,
                        `updated_by(${columns(`full_name`)})`
                    )
                )
                .eq('is_archived', false)
                .eq('order_id', orderId)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getPairs(orderId) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PAIRS)
                .select(
                    columns(
                        `*`,
                        `section:section_id(${columns(`name`, `warehouse`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`full_name`)})`,
                        `updated_by(${columns(`full_name`)})`
                    )
                )
                .eq('is_archived', false)
                .eq('order_id', orderId)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default OrderService;
