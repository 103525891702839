import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase, toSnakeCase, columns } from '../utils/utils';
class VendorService {
    async getAllVendors() {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.VENDORS)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('is_archived', false)
                .order('created_at', { ascending: false });

            if (error) throw error;

            return AppResult.withData(toCamelCase(data));
        });
    }

    async createVendor(payload) {
        return withSafety(async () => {
            const newObj = {
                ...toSnakeCase(payload),
                is_archived: false,
                created_by: authService.getCurrentUser().id
            };

            const { data, error } = await supabase.from(Table.VENDORS).insert([newObj]);

            if (error) throw error;

            return AppResult.withData(data);
        });
    }

    async updateVendor({ id, ...changes }) {
        return withSafety(async () => {
            const updatedObj = {
                ...toSnakeCase(changes),
                updated_by: authService.getCurrentUser().id,
                updated_at: `now()`
            };

            const { data, error } = await supabase
                .from(Table.VENDORS)
                .update(updatedObj)
                .eq('id', id);

            if (error) throw error;

            return AppResult.withData(data);
        });
    }

    async deleteVendor(id) {
        return await this.updateVendor({ id, isArchived: true });
    }

    async getVendorById(id) {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.VENDORS)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('id', id)
                .single();

            if (error) throw error;

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getVendorsRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from(Table.VENDORS)
                .select(
                    columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
                    { count: 'exact' }
                )
                .eq('is_archived', false)
                .range(from, to)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData({ vendors: toCamelCase(data), count });
        });
    }

    async getVendorWhereNameLike(name) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.VENDORS)
                .select(columns(`*`))
                .eq('is_archived', false)
                .ilike('name', `%${name}%`)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default VendorService;
