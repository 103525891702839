import { createGlobalStyle } from 'styled-components';

import { createTheme } from '@material-ui/core/styles';

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
        scroll-behavior:smooth;
        ::-webkit-scrollbar{
          display:none;
        }
    }
    
    *,
    *::before,
    *::after {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }
`;

export const theme = createTheme({
    palette: {
        primary: {
            main: '#5664D2'
        }
    },
    shape: {
        borderRadius: 16
    }
});
