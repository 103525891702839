import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase, columns } from '../utils/utils';

class ShipmentService {
    async getAllShipments() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.SHIPMENTS)
                .select(
                    columns(
                        `*`,
                        `party:party_id(${columns(`*`)})`,
                        `section:section_id(${columns(`*`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`*`)})`,
                        `updated_by(${columns(`*`)})`
                    )
                )
                .eq('is_archived', false)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async createShipment(payload) {
        return withSafety(async () => {
            const shipmentObj = {
                m_box_count: payload.boxCount,
                m_pair_count: payload.pairCount,
                m_party_id: payload.partyId,
                m_article_type_id: payload.articleTypeId,
                m_section_id: payload.sectionId,
                m_note: payload.note
            };

            console.log(shipmentObj);

            const { data } = await supabase.rpc('create_shipment', shipmentObj).throwOnError();

            return AppResult.withData(data);
        });
    }

    async activateShipment(id) {
        return withSafety(async () => {
            const { data, error } = await supabase.rpc('activate_shipment', {
                sid: id
            });

            if (error) throw error;

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getShipmentsRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from(Table.SHIPMENTS)
                .select(
                    columns(
                        `*`,
                        `party:party_id(${columns(`*`)})`,
                        `section:section_id(${columns(`*`)})`,
                        `article_type:article_type_id(${columns(`*`)})`,
                        `created_by(${columns(`*`)})`,
                        `updated_by(${columns(`*`)})`
                    ),
                    { count: 'exact' }
                )
                .eq('is_archived', false)
                .range(from, to)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData({ shipments: toCamelCase(data), count });
        });
    }

    async deleteShipment(id) {
        return withSafety(async () => {
            const { data, error } = await supabase.rpc('delete_shipment', {
                m_shipment_id: id
            });

            if (error) throw error;

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default ShipmentService;
