import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';

import { appConfig } from './utils/config';
import { GlobalStyle, theme } from './utils/theme';
import AppRouter from './presentation/router/AppRouter';
import { AuthProvider } from './contexts/AuthContext';

if (appConfig.isProduction) {
    console.log('In production');
    console.log = function () {};
    console.error = function () {};
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={4}>
            <AuthProvider>
                <AppRouter />
            </AuthProvider>
        </SnackbarProvider>
        <GlobalStyle />
    </ThemeProvider>,
    document.getElementById('root')
);
