import { camelCase, snakeCase } from 'lodash';
import imageCompression from 'browser-image-compression';
import { Table } from './common';

export const toCamelCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => toCamelCase(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: toCamelCase(obj[key])
            }),
            {}
        );
    }
    return obj;
};

export const toSnakeCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => toSnakeCase(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [snakeCase(key)]: toSnakeCase(obj[key])
            }),
            {}
        );
    }
    return obj;
};

export const columns = (...array) => {
    return array.join();
};

export const sleep = (delay) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), delay);
    });
};

export const mapToRowData = (model, data) => {
    return mappers[model](data);
};

const mappers = {
    loose: (batches) =>
        batches.map((item) => ({
            ...item,
            pairCount: item.pairCount === 0 ? 'N/A' : item.pairCount,
            vendor: item.vendor ? `${item.vendor.name} (${item.vendor.code})` : 'N/A',
            party: item.party ? `${item.party.name} (${item.party.code})` : 'N/A',
            articleTypeName: getArticleTypeDisplayName(item.articleType),
            section: `${item.section.name} (${item.section.warehouse})`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: `${item.createdBy.fullName}`
        })),
    pairs: (pairs) =>
        pairs.map((item) => ({
            ...item,
            articleType: getArticleTypeDisplayName(item.articleType),
            section: `${item.section.name} (${item.section.warehouse})`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: `${item.createdBy.fullName}`,
            updatedAt: item.updatedAt && `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy && `${item.updatedBy.fullName}`
        })),
    vendors: (vendors) =>
        vendors.map((item) => ({
            ...item,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: `${item.createdBy.fullName}`,
            updatedAt: item.updatedAt && `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy && `${item.updatedBy.fullName}`
        })),
    shipments: (shipments) =>
        shipments.map((item) => ({
            ...item,
            section: `${item.section.name} (${item.section.warehouse})`,
            party: `${item.party.name} (${item.party.code})`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: `${item.createdBy.fullName}`,
            updatedAt: item.updatedAt && `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy && `${item.updatedBy.fullName}`
        })),
    orders: (orders) =>
        orders.map((item) => ({
            ...item,
            articleTypeName: getArticleTypeDisplayName(item.articleType),
            vendor: `${item.vendor.name} (${item.vendor.code})`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: `${item.createdBy.fullName}`,
            updatedAt: item.updatedAt && `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy && `${item.updatedBy.fullName}`
        })),
    sections: (sections) =>
        sections.map((section) => ({
            ...section,
            createdAt: `${new Date(section.createdAt).toLocaleString()}`,
            createdBy: `${section.createdBy.fullName}`,
            updatedAt: section.updatedAt && `${new Date(section.updatedAt).toLocaleString()}`,
            updatedBy: section.updatedBy && `${section.updatedBy.fullName}`
        })),
    parties: (parties) =>
        parties.map((p) => ({
            ...p,
            createdAt: `${new Date(p.createdAt).toLocaleString()}`,
            createdBy: `${p.createdBy.fullName}`,
            updatedAt: p.updatedAt && `${new Date(p.updatedAt).toLocaleString()}`,
            updatedBy: p.updatedBy && `${p.updatedBy.fullName}`
        })),
    boxes: (boxes) =>
        boxes.map((item) => ({
            ...item,
            articleTypeName: getArticleTypeDisplayName(item.articleType),
            section: `${item.section.name} (${item.section.warehouse})`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: `${item.createdBy.fullName}`,
            updatedAt: item.updatedAt && `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy && `${item.updatedBy.fullName}`
        })),
    article_types: (articleTypes) =>
        articleTypes.map((item) => ({
            ...item,
            sizeWithUnit: `${item.size} (${item.sizeUnit})`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: `${item.createdBy.fullName}`,
            updatedAt: item.updatedAt && `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy && `${item.updatedBy.fullName}`
        })),
    purchase_orders: (pos) =>
        pos.map((item) => ({
            id: item.id,
            articleType: mapToRowData(Table.ARTICLE_TYPES, [item])[0],
            minPair: item.minPair,
            pairsLeft: item.pairsLeft
        }))
};

export const getArticleTypeDisplayName = (a) =>
    a ? `${a.name}/${a.color}-${a.size} (${a.sizeUnit})` : ``;

export const compressImage = async (file, options) => {
    const compressed = await imageCompression(file, options);
    return compressed;
};
