import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { supabaseAuth } from '../adapters/supabase';
import { authService } from '../services';

const useCurrentUser = () => {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (authService.getCurrentUser() != null) {
            fetch();
        } else {
            setLoading(false);
        }

        const { data } = supabaseAuth.onAuthStateChange(async (event, session) => {
            if (session?.user != null) {
                const { data, error } = await authService.fetchProfile();
                if (error || data == null) {
                    return enqueueSnackbar(error || 'Something went wrong', { variant: 'error' });
                }

                setCurrentUser(data);
            } else {
                setCurrentUser(null);
            }
        });

        return data.unsubscribe;
    }, []);

    const fetch = async () => {
        const { data, error } = await authService.fetchProfile();
        if (error || data == null) {
            return enqueueSnackbar(error || 'Something went wrong', { variant: 'error' });
        }

        setCurrentUser(data);
        setLoading(false);
    };

    return { currentUser, loading };
};

export default useCurrentUser;
