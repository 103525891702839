import { lazy } from 'react';

export const routes = [
    {
        name: 'login',
        path: '/login',
        component: lazy(() => import('../pages/login/LoginPage')),
        isProtected: false,
        exact: true
    },
    {
        name: 'app',
        path: '/app',
        component: lazy(() => import('../pages/home/HomePage')),
        isProtected: true,
        routes: [
            {
                name: 'app_items',
                path: '/app/items',
                component: lazy(() => import('../pages/boxes/ListBoxes')),
                isProtected: true
            },
            {
                name: 'app_staff',
                path: '/app/staff',
                component: lazy(() => import('../pages/staff/ListStaff')),
                isProtected: true
            },
            {
                name: 'app_orders',
                path: '/app/orders',
                component: lazy(() => import('../pages/orders/ListOrders')),
                isProtected: true
            },
            {
                name: 'app_article_types',
                path: '/app/article-types',
                component: lazy(() => import('../pages/article-types/ListArticleTypes')),
                isProtected: true
            },
            {
                name: 'app_vendors',
                path: '/app/vendors',
                component: lazy(() => import('../pages/vendors/ListVendors')),
                isProtected: true
            },
            {
                name: 'app_sections',
                path: '/app/sections',
                component: lazy(() => import('../pages/sections/ListSections')),
                isProtected: true
            },
            {
                name: 'app_loose',
                path: '/app/loose',
                component: lazy(() => import('../pages/loose/ListLoose')),
                isProtected: true
            },
            {
                name: 'app_parties',
                path: '/app/parties',
                component: lazy(() => import('../pages/parties/ListParties')),
                isProtected: true
            },
            {
                name: 'app_shipments',
                path: '/app/shipments',
                component: lazy(() => import('../pages/shipments/ListShipments')),
                isProtected: true
            },
            {
                name: 'search',
                path: '/app/dashboard',
                exact: true,
                component: lazy(() => import('../pages/dashboard/Dashboard')),
                isProtected: true
            },
            {
                name: 'purchase_order',
                path: '/app/purchase-orders',
                exact: true,
                component: lazy(() => import('../pages/purchase-orders/ListPurchaseOrders')),
                isProtected: true
            },
            {
                name: 'dashboard_details',
                path: '/app/dashboard/:status',
                exact: true,
                component: lazy(() => import('../pages/status-list/StatusList')),
                isProtected: true
            }
        ]
    }
];
