import axios from 'axios';
import { authService } from '.';
import { supabase, supabaseAuth } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { columns, toCamelCase } from '../utils/utils';

class StaffService {
    async createUser(details) {
        return withSafety(async () => {
            await axios.post('/api/users', details, {
                headers: {
                    authorization: supabaseAuth?.session()?.access_token
                }
            });

            return AppResult.withData();
        });
    }

    async getMembers() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PROFILES)
                .select(columns(`*`, `created_by(full_name)`, `updated_by(full_name)`))
                .neq('id', authService.getCurrentUser().id)
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async updateUser(details) {
        return withSafety(async () => {
            await axios.put('/api/users', details, {
                headers: {
                    authorization: supabaseAuth?.session()?.access_token
                }
            });

            return AppResult.withData();
        });
    }

    async resetPassword({ id, password }) {
        return withSafety(async () => {
            await axios.put(
                '/api/reset',
                { id, password },
                {
                    headers: {
                        authorization: supabaseAuth?.session()?.access_token
                    }
                }
            );

            return AppResult.withData();
        });
    }
}

export default StaffService;
