import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase, toSnakeCase, columns, compressImage } from '../utils/utils';

class ArticleTypeService {
    async getAllArticleTypes() {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.ARTICLE_TYPES)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('is_archived', false)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }

    async createArticleType({ file, ...payload }) {
        return withSafety(async () => {
            let compressedFile = null;
            let compressedThumbnail = null;

            const newObj = toSnakeCase({
                ...payload,
                isArchived: false,
                createdBy: authService.getCurrentUser().id
            });

            if (file != null) {
                compressedFile = await compressImage(file, {
                    maxSizeMB: 0.6,
                    useWebWorker: true
                });
                console.log('compressing thumbnail');
                compressedThumbnail = await compressImage(file, {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 100,
                    useWebWorker: true
                });
                console.log('compressed thumbnail: ', compressedThumbnail);
            }

            const { data } = await supabase
                .from(Table.ARTICLE_TYPES)
                .insert([newObj])
                .throwOnError();

            if (file != null) {
                console.log(compressedFile);
                await supabase.storage
                    .from('article-types')
                    .upload(`private/${data[0].id}.${payload.imageExt}`, compressedFile, {
                        cacheControl: '3600',
                        upsert: true
                    });

                console.log(compressedThumbnail);
                await supabase.storage
                    .from('article-types')
                    .upload(`private/${data[0].id}-t.${payload.imageExt}`, compressedThumbnail, {
                        cacheControl: '3600',
                        upsert: true
                    });
            }

            return AppResult.withData(data);
        });
    }

    async updateArticleType({ id, file, ...changes }) {
        return withSafety(async () => {
            let compressedFile = null;
            let compressedThumbnail = null;

            const articleType = toSnakeCase({
                ...changes,
                updated_by: authService.getCurrentUser().id,
                updated_at: `now()`
            });

            if (file != null) {
                compressedFile = await compressImage(file, {
                    maxSizeMB: 0.6,
                    useWebWorker: true
                });
                console.log('compressing thumbnail');
                compressedThumbnail = await compressImage(file, {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 100,
                    useWebWorker: true
                });
                console.log('compressed thumbnail: ', compressedThumbnail);
            }

            const { data } = await supabase
                .from(Table.ARTICLE_TYPES)
                .update(articleType)
                .eq('id', id)
                .throwOnError();

            if (file != null) {
                console.log(compressedFile);
                await supabase.storage
                    .from('article-types')
                    .upload(`private/${data[0].id}.${changes.imageExt}`, compressedFile, {
                        cacheControl: '3600',
                        upsert: true
                    });

                console.log(compressedThumbnail);
                await supabase.storage
                    .from('article-types')
                    .upload(`private/${data[0].id}-t.${changes.imageExt}`, compressedThumbnail, {
                        cacheControl: '3600',
                        upsert: true
                    });
            }

            return AppResult.withData(data);
        });
    }

    async deleteArticleType(id) {
        return await this.updateArticleType({ id, isArchived: true });
    }

    async getArticleTypeById(id) {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.ARTICLE_TYPES)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('id', id)
                .single();

            if (error) throw new Error(error.message);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getArticleTypesRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from(Table.ARTICLE_TYPES)
                .select(
                    columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
                    { count: 'exact' }
                )
                .eq('is_archived', false)
                .range(from, to)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData({ articleTypes: toCamelCase(data), count });
        });
    }

    async getImageUrl(id, ext) {
        return withSafety(async () => {
            const { data, error } = supabase.storage
                .from('article-types')
                .getPublicUrl(`private/${id}.${ext}`);

            console.log(data, error);
            if (error) throw error;

            return AppResult.withData({ url: data.publicURL });
        });
    }

    getThumbnailUrl(id, ext) {
        const { data } = supabase.storage
            .from('article-types')
            .getPublicUrl(`private/${id}-t.${ext}`);

        return data.publicURL;
    }

    async getArticleTypeWhereNameLike(name) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.ARTICLE_TYPES)
                .select(columns(`*`))
                .eq('is_archived', false)
                .ilike('name', `%${name}%`)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default ArticleTypeService;
