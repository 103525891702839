import { Suspense, useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';

import AppRoute from './AppRoute';
import { routes } from './routes';

import { AuthContext } from '../../contexts/AuthContext';

const AppRouter = () => {
    const { currentUser, loading } = useContext(AuthContext);

    if (loading) return <LinearProgress />;

    return (
        <Suspense fallback={<LinearProgress />}>
            <BrowserRouter>
                <Switch>
                    {routes.map((route) => (
                        <AppRoute
                            {...route}
                            key={route.name}
                            isAuthenticated={currentUser != null}
                        />
                    ))}
                    <Route render={() => <Redirect to={{ pathname: '/app/dashboard' }} />} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
};

export default AppRouter;
