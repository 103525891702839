import { supabase } from '../adapters/supabase';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase } from '../utils/utils';

class PurchaseOrderService {
    async getAllPurchaseOrders() {
        return withSafety(async () => {
            const { data } = await supabase.rpc('get_all_pos').throwOnError();

            console.log(data);

            if (data.length < 1) throw new Error('Nothing found');

            const filteredData = data.filter((r) => r.pairs_left < r.min_pair);
            return AppResult.withData(toCamelCase(filteredData));
        });
    }
}

export default PurchaseOrderService;
