import { authService } from '.';
import { supabase } from '../adapters/supabase';
import { Table } from '../utils/common';
import AppResult, { withSafety } from '../utils/result';
import { toCamelCase, toSnakeCase, columns } from '../utils/utils';

class PartyService {
    async getAllParties() {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.PARTIES)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('is_archived', false)
                .order('created_at', { ascending: false });

            if (error) throw error;

            return AppResult.withData(toCamelCase(data));
        });
    }

    async createParty(payload) {
        return withSafety(async () => {
            const newObj = {
                ...toSnakeCase(payload),
                is_archived: false,
                created_by: authService.getCurrentUser().id
            };

            const { data, error } = await supabase.from(Table.PARTIES).insert([newObj]);

            if (error) throw new Error(error.message);

            return AppResult.withData(data);
        });
    }

    async updateParty({ id, ...changes }) {
        return withSafety(async () => {
            const Party = {
                ...toSnakeCase(changes),
                updated_by: authService.getCurrentUser().id,
                updated_at: `now()`
            };

            const { data, error } = await supabase.from(Table.PARTIES).update(Party).eq('id', id);

            if (error) throw new Error(error.message);

            return AppResult.withData(data);
        });
    }

    async deleteParty(id) {
        return await this.updateParty({ id, isArchived: true });
    }

    async getPartyById(id) {
        return withSafety(async () => {
            const { data, error } = await supabase
                .from(Table.PARTIES)
                .select(columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`))
                .eq('id', id)
                .single();

            if (error) throw new Error(error.message);

            return AppResult.withData(toCamelCase(data));
        });
    }

    async getPartiesRanged({ from, to }) {
        return withSafety(async () => {
            const { data, count } = await supabase
                .from(Table.PARTIES)
                .select(
                    columns(`*`, `created_by(${columns(`*`)})`, `updated_by(${columns(`*`)})`),
                    { count: 'exact' }
                )
                .eq('is_archived', false)
                .range(from, to)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData({ parties: toCamelCase(data), count });
        });
    }

    async getPartyWhereNameLike(name) {
        return withSafety(async () => {
            const { data } = await supabase
                .from(Table.PARTIES)
                .select(columns(`*`))
                .eq('is_archived', false)
                .ilike('name', `%${name}%`)
                .order('created_at', { ascending: false })
                .throwOnError();

            return AppResult.withData(toCamelCase(data));
        });
    }
}

export default PartyService;
